import React from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";

function Page() {
  return (
    <div id='middlschool-page' className='bg-slate-50 lg:px-48'>
      <LazyLoadImage
        src='/cursus/middle-school.png'
        width='100%'
        height='100%'
        className='m-auto mt-4'
      />
    </div>
  );
}

export default Page;
