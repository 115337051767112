import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faClose,
  faEnvelope,
  faPhone
} from "@fortawesome/free-solid-svg-icons";
import { useOnClickOutside } from "usehooks-ts";
import packageJson from "../../../package.json";

import { isAuth } from "utils/context";
import { useUser } from "context/user.context";
import AuthMenu from "components/auth/AuthMenu";

import { UsersAPI } from "api";

export const NAV_ITEM_STYLE =
  "font-semibold px-6 hover:text-sky-800 py-2 lg:py-0";
const DROPDOWN_ITEM_STYLE = "px-8 py-2 min-w-[max-content] hover:bg-gray-200";

const Header = () => {
  const { ctxUser, changeToken, changeAuthStatus, changeUser } = useUser();
  const cursusRef = useRef<HTMLDivElement>(null);

  const [showMobileMenus, setShowMobileMenus] = useState<boolean>(false);
  const [showCursus, setShowCursus] = useState<boolean>(false);

  const closeMenus = useCallback(() => {
    setShowMobileMenus(false);
    setShowCursus(false);
  }, []);

  const handleClickOutsideCursus = useCallback(() => {
    setShowCursus(false);
  }, []);

  useOnClickOutside(cursusRef, handleClickOutsideCursus);

  const menuItems = useMemo(() => {
    return (
      <div className='nav-items flex flex-col lg:flex-row uppercase text-slate-500'>
        <Link className={NAV_ITEM_STYLE} to='/' onClick={() => closeMenus()}>
          Home
        </Link>
        <div ref={cursusRef} className='cursus relative'>
          <button
            className={NAV_ITEM_STYLE}
            onClick={() => setShowCursus(!showCursus)}
          >
            <span className='font-semibold uppercase mr-2'>Cursus</span>
            <FontAwesomeIcon icon={showCursus ? faChevronUp : faChevronDown} />
          </button>
          {showCursus && (
            <div className='cursus-items relative lg:absolute lg:top-10 lg:right-0 flex flex-col bg-white lg:shadow-xl min-w-max'>
              <Link
                className={DROPDOWN_ITEM_STYLE}
                to='/daycare'
                onClick={() => closeMenus()}
              >
                day care
              </Link>
              <Link
                className={DROPDOWN_ITEM_STYLE}
                to='/preschool'
                onClick={() => closeMenus()}
              >
                pre school
              </Link>
              <Link
                className={DROPDOWN_ITEM_STYLE}
                to='/primaryschool'
                onClick={() => closeMenus()}
              >
                primary school
              </Link>
              <Link
                className={DROPDOWN_ITEM_STYLE}
                to='/middleschool'
                onClick={() => closeMenus()}
              >
                middle school
              </Link>
              <Link
                className={DROPDOWN_ITEM_STYLE}
                to='/highschool'
                onClick={() => closeMenus()}
              >
                high school
              </Link>
            </div>
          )}
        </div>
        <Link
          className={NAV_ITEM_STYLE}
          to='/news'
          onClick={() => closeMenus()}
        >
          news
        </Link>
        <Link
          className={NAV_ITEM_STYLE}
          to='/contact'
          onClick={() => closeMenus()}
        >
          contact
        </Link>
        <Link
          className={NAV_ITEM_STYLE}
          to='/registration'
          onClick={() => closeMenus()}
        >
          registration
        </Link>
      </div>
    );
  }, [showCursus]);

  const persistContext = useCallback(async () => {
    if (isAuth() && !ctxUser?.id) {
      changeToken(localStorage.getItem("token"));

      const resUser = await UsersAPI.getUser();

      if (!resUser.error) {
        changeUser(resUser);
        changeAuthStatus(true);
      }
    }
  }, [ctxUser, isAuth, changeToken, changeAuthStatus, changeUser]);

  useEffect(() => {
    persistContext();
  }, []);

  return (
    <nav className='w-screen fixed top-0 left-0 shadow-lg min-h-24 py-2 px-8 h-[max-content] flex items-center justify-between z-10 bg-white'>
      {/* NAVBAR BRAND */}
      <div className='nav-brand flex'>
        <Link to='/' className='w-20 h-24 mr-6' title={packageJson.version}>
          <LazyLoadImage src='/images/logo.png' width={80} height={93} />
        </Link>
        <div className='nav-brand-meta hidden lg:flex flex-col justify-center text-slate-600'>
          <Link to='/' className='font-bold'>
            Elementary School of Antananarivo
          </Link>
          <a href='tel:+261 34 19 302 87'>
            <FontAwesomeIcon icon={faPhone} />
            <span className='ml-2'>034 19 302 87</span>
          </a>
          <a href='mailto:contact@esa-mada.com'>
            <FontAwesomeIcon icon={faEnvelope} />
            <span className='ml-2'>contact@esa-mada.com</span>
          </a>
        </div>
      </div>

      {/* NAVBAR ITEMS */}
      <div className='hidden lg:flex flex-col lg:flex-row uppercase ml-auto'>
        {menuItems}
      </div>

      <div className='flex'>
        {/* NAVBAR DROPDOWN */}
        <button
          type='button'
          className='inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200'
          onClick={() => setShowMobileMenus(!showMobileMenus)}
        >
          <span className='sr-only'>Open main menu</span>
          <svg
            className='w-5 h-5'
            aria-hidden='true'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 17 14'
          >
            <path
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M1 1h15M1 7h15M1 13h15'
            />
          </svg>
        </button>

        <AuthMenu />
      </div>

      {showMobileMenus && (
        <div className='fixed min-h-full max-h-100dvh top-0 left-0 flex flex-col lg:hidden bg-white h-[max-content] shadow-xl shadow-slate-400 py-4 w-72 z-10'>
          <button
            className='w-full flex items-center justify-end px-8 text-slate-500 text-xl'
            onClick={() => setShowMobileMenus(!showMobileMenus)}
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
          {menuItems}
        </div>
      )}
    </nav>
  );
};

export default Header;
