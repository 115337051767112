import { CURSUS, Image } from "utils/types/general";
import { PRICE } from "utils/types/prices";

export const HOME_CURSUS: CURSUS[] = [
  {
    name: "Day care",
    interval: "from 18 months to 3 years old",
    color: "bg-esaDaycare hover:opacity-50",
    path: "/daycare"
  },
  {
    name: "Preschool",
    interval: "from 3 to 5 years old",
    color: "bg-esaPreschool hover:opacity-50",
    path: "/preschool"
  },
  {
    name: "Primary",
    interval: "from 6 to 10 years old",
    color: "bg-esaPrimaryschool hover:opacity-50",
    path: "/primaryschool"
  },
  {
    name: "Middle school",
    interval: "from 11 years old",
    color: "bg-esaMiddleschool hover:opacity-50",
    path: "/middleschool"
  },
  {
    name: "High school",
    interval: "from 14 years old",
    color: "bg-esaHighschool hover:opacity-50",
    path: "/highschool"
  }
];

export const HOME_FAMILY_PICTURES: Image[] = [
  { src: "/images/family1.jpg", alt: "family1" },
  { src: "/images/family2.jpg", alt: "family2" },
  { src: "/images/family3.jpg", alt: "family3" },
  { src: "/images/family4.jpg", alt: "family4" },
  { src: "/images/family5.jpg", alt: "family5" },
  { src: "/images/family6.jpg", alt: "family6" },
  { src: "/images/family7.jpg", alt: "family7" },
  { src: "/images/family8.jpg", alt: "family8" },
  { src: "/images/family9.jpg", alt: "family9" },
  { src: "/images/family10.jpg", alt: "family10" }
];

export const PRICE_DEFAULT: PRICE = {
  application_deadline: "2023-05-12",
  id: "y9fpAvtJRvurW2ZdyyHR",
  school_year: "2023-2024",
  apply_to_website: true,
  category: "Ecolage",
  prices: {
    insurance: {
      daycare: 10000,
      pre_school: 10000,
      elementary: 10000,
      comment: "",
      label: "Insurance",
      middle_school: 10000
    },
    book_photocopy: {
      daycare: 20000,
      pre_school: 270000,
      elementary: 300000,
      comment: "",
      label: "Book / Photocopy",
      middle_school: 350000
    },
    total: {
      daycare: {
        part_time: 690000,
        full_time: 770000
      },
      pre_school: 970000,
      elementary: 1160000,
      comment: "",
      label: "Total",
      middle_school: 1330000
    },
    materials: {
      daycare: 260000,
      pre_school: 160000,
      elementary: 220000,
      comment: "",
      label: "Materials",
      middle_school: 240000
    },
    registration_fee: {
      daycare: 200000,
      pre_school: 200000,
      elementary: 200000,
      comment: "",
      label: "Registration Fee",
      middle_school: 200000
    },
    re_registration: {
      daycare: 110000,
      pre_school: 110000,
      elementary: 110000,
      comment: "",
      label: "Re-registration",
      middle_school: 110000
    },
    september_fee: {
      daycare: {
        part_time: 200000,
        full_time: 280000
      },
      pre_school: 330000,
      elementary: 430000,
      comment: "",
      label: "September fee",
      middle_school: 530000
    },
    annual: {
      daycare: {
        part_time: 1700000,
        full_time: 2380000
      },
      pre_school: 2805000,
      elementary: 3655000,
      comment: "",
      middle_school: 4505000,
      label: "Annual payment"
    },
    monthly: {
      daycare: {
        part_time: 200000,
        full_time: 280000
      },
      pre_school: 330000,
      elementary: 430000,
      comment: "",
      middle_school: 530000,
      label: "Monthly payment"
    },
    trimester: {
      daycare: {
        part_time: 600000,
        full_time: 840000
      },
      pre_school: 990000,
      elementary: 1290000,
      comment: "",
      middle_school: 1590000,
      label: "Trimester payment"
    }
  },
  version: "v2"
};
